import React, { useEffect, useState, useRef } from "react";
import { USERS_ICON, EXPORT_ICON, MINUS_ICON,TRASH_ICON } from "assets/constants/Icons";
import { TYPE_SUCCESS, TYPE_FAIL, INTERVAL, TYPE_INFO, BELOW_EIGHTEEN_COLOR } from "assets/constants/Constants";
import moment from 'moment';
import MaterialTable, { MTableToolbar } from "material-table";
import ExcelExportComponent from "components/ExcelExportComponent";
import ShowToast from 'components/ShowToast';
import {importCandidate} from 'components/ImportCandidateComponent';
import ImportErrorPopup from 'components/ImportErrorPopup';
import { registerBulkCandidate, validateCandidateData, getAge } from 'lib/utils';
import { getEmployerList, getPartnerList, getCandidateList, deleteCandidate , updateProgramCall} from "lib/axiosUtils";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';

const CandidateManagementPage = (props) => {
    const [programId] = useState(props.location.state ? props.location.state.programId : null);
    const [candidateSplit, setCandidateSplit] = useState(props.location.state ? props.location.state.candidateSplit : []);
    const [employerList, setEmployerList] = useState([]);
    const [partnerList, setPartnerList] = useState([]);
    const [candidateList, setCandidateList] = useState([]);
    const [selectedCandidateList, setSelectedCandidateList] = useState([]);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [candidateImportData, setCandidateImportData] = useState([]);
    const [importLoading, setImportLoading] = useState(false);
    const [unitSplitArray, setUnitSplitArray] = useState([]);
    const [departmentSplitArray, setDepartmentSplitArray] = useState([]);
    const [unitSplitData, setUnitSplitData] = useState(props.location.state ? props.location.state.unitSplitData : []);
    const [partnerSplitArray, setPartnerSplitArray] = useState([]);
    const [partnerSplitData, setPartnerSplitData] = useState(props.location.state ? props.location.state.partnerSplitData : []);
    const [removedCandidateProject] = useState([]);
    const fileInputRef = useRef(null);
    const mytheme =  createTheme({});

    useEffect(() => {
        if(props.location.state){
            setDepartmentSplitArray(props.location.state.departmentList)
            let employerUnit = [];
            if(props.location.state.employerItem && props.location.state.employerItem.length > 0 ){
                props.location.state.employerItem.forEach((item) => {
                    employerUnit.push(item.unit_name);
                })
            }
            setUnitSplitArray(employerUnit);
            let splitArrayUnit ={};
            if (employerUnit && employerUnit.length > 0 && unitSplitData.length > 0) {
                unitSplitData.forEach((item) => {
                    const foundObject = props.location.state.employerItem.find((findItem) => findItem._id === item.unit_id);
                    if (foundObject) {
                        splitArrayUnit[foundObject.unit_name] = {
                            ...splitArrayUnit[foundObject.unit_name],
                            [item.department]: item.candidate_needed,
                        }
                    }
                })
                setUnitSplitData(splitArrayUnit);
            }
        }
    },[props, unitSplitData])

    useEffect(() =>{
        if(props.location.state){
            setDepartmentSplitArray(props.location.state.departmentList)
            let trainingPartner = [];
            if(props.location.state.partnerItem && props.location.state.partnerItem.length > 0 ){
                props.location.state.partnerItem.forEach((item) => {
                    trainingPartner.push(item.training_partner);
                })
            }
            setPartnerSplitArray(trainingPartner);
            let splitArrayPartner ={};
            if (trainingPartner && trainingPartner.length > 0 && partnerSplitData.length > 0) {
                partnerSplitData.forEach((item) => {
                    const foundObject = props.location.state.partnerItem.find((findItem) => findItem._id === item.partner_id);
                    if (foundObject) {
                        splitArrayPartner[foundObject.training_partner] = {
                            ...splitArrayPartner[foundObject.training_partner],
                            [item.department]: item.candidate_needed,
                        }
                    }
                })
                setPartnerSplitData(splitArrayPartner);
            }
        }
    },[props,partnerSplitData])

    useEffect(() => {
        const fetchEmployerList = async () => {
            try {
                const data = await getEmployerList({},{});
                setEmployerList(data.data.result);
            }
            catch (error) {
                console.log("error while fetching data", error);
            }
        }
        fetchEmployerList();

        const fetchPartnerList = async () => {
            try {
                const response = await getPartnerList({},{});
                const data = response.data.result;
                setPartnerList(data);
            }
            catch (error) {
                console.log("Error Occurred while fetching training partner list", error);
            }
        }
        fetchPartnerList();
    }, [props]);

    useEffect(() => {
        fetchCandidateList();
        // eslint-disable-next-line
    }, [props]);

    const cancelHandle = () =>{ 
        if(isModelOpen === true){
            setIsModelOpen(false);
            window.location.reload();
        }
    }

    const importCandidateExcel = async () => {
        fileInputRef.current.click();
    }

    const columns = [
        { title: "First Name", field: "firstName", cellStyle: { width: '12% !important', fontWeight: 'bolder', color: '#000000', fontSize: 14 } },
        { title: "Last Name", field: "lastName", cellStyle: { width: '12%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Fathers Name", field: "fatherName", cellStyle: { width: '12%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Department Selected", field: "departmentSelected", cellStyle: { width: '9%', color: '#808080', fontSize: 14 } },
        { title: "Training Partner", field: "trainingPartner", cellStyle: { width: '9%', color: '#808080', fontSize: 14 } },
        { title: "Employer Unit", field: "employerUnit", cellStyle: { width: '9%', color: '#808080', fontSize: 14 } },
        { title: "DOB", field: "disaplayDobDate", cellStyle: { width: '5%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Age", field: "ageNumber", cellStyle: { width: '5%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Contact No", field: "mobileNumber", cellStyle: { width: '10%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Aadhar No.", field: "adharNo", cellStyle: { width: '10%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Email Id", field: "email", cellStyle: { width: '9%', color: '#808080', fontSize: 14 } },
        { title: "Qualification", field: "qualification", cellStyle: { width: '5%', color: '#808080', fontSize: 14 } },
        { title: "Gender", field: "gender", cellStyle: { width: '5%', color: '#808080', fontSize: 14 } },
        { title: "Candidate State", field: "state", cellStyle: { width: '9%', color: '#808080', fontSize: 14 } },
        { title: "Candidate City", field: "city", cellStyle: { width: '9%', color: '#808080', fontSize: 14 } },
        { title: "Creation Date", field: "creationDate", cellStyle: { width: '9%', color: '#808080', fontSize: 14 } },
    ];

    const fetchCandidateList = async (importData = null) => {
        try {
            let previousData = [];
            if (candidateList.length > 0) {
                previousData = [...candidateList];
            }
            const data = await getCandidateList({},{});
            let list = [...data.data.result];
            if (importData === null) {
                if (list.length > 0) {
                    list.forEach((item) => {
                        item.selected = false;
                    });
                }
                if (props.location.state) {
                    const candidateId = [...props.location.state.candidateId]
                    list.forEach((listItem) => {
                        candidateId.forEach((item) => {
                            if (listItem._id === item) {
                                listItem.selected = true;
                            }
                        })
                    });
                }
            }
            else if (importData && importData.length > 0) {
                list.forEach((item) => {
                    item.selected = false;
                    importData.forEach((importItem) => {
                        if (importItem._id === item._id) {
                            item.selected = true;
                        }
                    })
                    previousData.forEach((previousItem) => {
                        if (previousItem._id === item._id) {
                            if (previousItem.selected === true) {
                                item.selected = true;
                            }
                        }
                    })
                });
            }
            list.sort((a, b) => {
                if (a.selected && !b.selected) {
                    return -1;
                } else if (!a.selected && b.selected) {
                    return 1;
                } else {
                    return 0;
                }
            });
            list.forEach((item) => {
                if (item.date_of_birth) {
                    const dateOfBirthForAge = item.date_of_birth.split("T")[0];
                    const ageNumber = getAge(dateOfBirthForAge);
                    item.ageBelowEighteen = ageNumber >= 18 ? false : true;
                }
                else {
                    item.ageBelowEighteen = false;
                }
            });
            setCandidateList(list);
            const selectedList = list.filter((item) => item.selected === true);
            setLoadingComplete(true);
            setSelectedCandidateList(selectedList);
            updateCandidateSplit(selectedList);
        }
        catch (error) {
            console.log("Error Occurred while fetching candidate list", error);
        }
    }

    const updateCandidateSplit = (selectedList) => {
        let splitArray = [...candidateSplit];
        splitArray.forEach((splitCandidate) => {
            let selectedCount = 0;
            selectedList.forEach((item) => {
                if (splitCandidate.candidateDepartment === item.department_selected) {
                    selectedCount++;
                }
            })
            splitCandidate.candidateSelected = selectedCount;
            const neededCount = parseInt(splitCandidate.candidateNeeded);
            if (neededCount) {
                splitCandidate.remainingCount = neededCount - selectedCount;
            }
        })
        setCandidateSplit(splitArray);
    }

    const handleCandidateExcelUpload = async(event) => {
        setImportLoading(true);
        try{
            if(event && event.target && event.target.files[0]){
                const requestData = await importCandidate(event, partnerList, employerList, programId, [],false, false);
                if(requestData && requestData.length > 0){
                    const validateFlag = validateCandidateData(requestData);
                    let importData = null;
                    try {
                        if (validateFlag) {
                            importData = await registerBulkCandidate(requestData);
                        }
                        else {
                            setIsModelOpen(true);
                            setCandidateImportData(requestData)
                        }
                        if(importData){
                            await fetchCandidateList(importData);
                        }
                        setImportLoading(false);
                    }
                    catch(error){
                        console.log("Error Occurred while importing candidate",error);
                        setImportLoading(false);
                    }
                }
                else{
                    ShowToast("No Candidate in Excel for Importing", TYPE_INFO, INTERVAL, "", "");
                    setImportLoading(false);
                }
            }
            else{
                ShowToast("Please select candidate excel for Importing", TYPE_INFO, INTERVAL, "", "");
                setImportLoading(false);
            }
        }
        catch(error){
            console.log("Error Occurred while importing",error);
            setImportLoading(false);
        }
    }

    const actions = [
        {
            icon: () => <i className={`${MINUS_ICON}`} title="Remove Candidate"></i>,
            onClick: (_, rowData) => handleRemove(rowData.id),
        },
        {
            icon: () => <i className={`${TRASH_ICON}`} title="Delete Candidate"></i>,
            onClick: (_, rowData) => handleDelete(rowData.id),
        }
    ];

    const handleRemove = (candidateId) => {
        updateSplitCandidate(selectedCandidateList,candidateId);
        let listCandidate = selectedCandidateList.filter((item) => item._id !== candidateId)
        setSelectedCandidateList(listCandidate);
        removedCandidateProject.push(candidateId);
    }

    const updateSplitCandidate = (selectedCandidateList,candidateId) => {
        let removedCandidate = selectedCandidateList.find((item) => item._id === candidateId);
        let splitArray = [];
        candidateSplit.forEach((item) => {
            if(removedCandidate.department_selected === item.candidateDepartment){
                const selectCandidate = parseInt(item.candidateSelected);
                item.candidateSelected = selectCandidate - 1 ;
                const remainingCount = parseInt(item.remainingCount);
                item.remainingCount = remainingCount + 1;
            }
            splitArray.push(item);
        });
        setCandidateSplit(splitArray);
    }

    const handleDelete = async (candidateId) => {
        if (window.confirm("Are you sure you want to delete this Candidate?")) {
            try {
                await deleteCandidate({},{},candidateId)
                let listCandidate = selectedCandidateList.filter((item) => item._id !== candidateId)
                setSelectedCandidateList(listCandidate);
                ShowToast("Candidate deleted successfully", TYPE_SUCCESS, INTERVAL, "", "");
            } catch (error) {
                ShowToast("Something went wrong. Please delete again after sometime!", TYPE_FAIL, INTERVAL, "", "");
            }
        }
    };

    const submitDetails = async () => {
        let candidateArray = selectedCandidateList.map((item) => {
            return item._id;
        })
        const programParams = {
            candidateId: candidateArray,
            uncheckedCandidates: removedCandidateProject
        };

        try {
            await updateProgramCall({},programParams,programId);
            ShowToast('Project Updated successfully', TYPE_SUCCESS, INTERVAL, props, "/project-list");
        }
        catch (error) {
            console.log("Error Occurred while updating the project", error);
        }
    }


    return (
        <>
            <div className="main-content candidate-management-outer">
                <div className="pagetitle row" style={{ color: 'black', paddingTop: 15 }}>
                    <div className="col-md-10 sm_device"><i className={`${USERS_ICON}`}></i> Candidate Management</div>
                    <div className="col-md-2 sm_device" style={{ textAlign: 'right' }}><i className={``}></i> {moment().format("DD MMM YYYY")}</div>
                </div>
                <div style={{ borderBottom: '1px solid lightgray', marginBottom: 15, marginTop: 15 }} />

                <div className="pagetitle row" style={{ color: 'black', paddingTop: 15 }}>
                    <div className="col-md-10 sm_device"><i className={`fa fa-columns icon`}></i> Candidate Split</div>
                </div>
                <div style={{ borderBottom: '1px solid lightgray', marginBottom: 15, marginTop: 15 }} />
                <div className="program-modal-inner-div candidate-management-inner">
                    {
                        departmentSplitArray.length > 0 && unitSplitArray.length > 0 && (Object.keys(unitSplitData).length > 0) && (<>
                            <p className="department-employer-table-heading">Department Employer Unit Table</p>
                            <table className="split-table">
                                <thead>
                                    <tr className="program-modal-main-row-header">
                                        <th></th>
                                        {unitSplitArray.map((unit, index) => (
                                            <th key={index}>{unit}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        departmentSplitArray.map((department, index) => {
                                            let rowClass = "";
                                            if (index % 2) {
                                                rowClass = "program-modal-row-background-color"
                                            }
                                            return (
                                                <tr key={index} className={rowClass}>
                                                    <td className="padding-right-10 padding-left-5">{department}</td>
                                                    {
                                                        unitSplitArray.map((unit, subIndex) => {
                                                            return (
                                                                <td key={subIndex}>
                                                                    {unitSplitData[unit][department]}
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </>)
                    }
                    {/* -------------------Department Trainging Partner Table------------------------------------ */}
                    <div className="padding-top-20">
                        {
                            departmentSplitArray.length > 0 && partnerSplitArray.length > 0 && (Object.keys(partnerSplitData).length > 0) && (<>
                                <p className="department-employer-table-heading">Department Training Partner Table</p>
                                <table className="split-table">
                                    <thead>
                                        <tr className="program-modal-main-row-header">
                                            <th></th>
                                            {partnerSplitArray.map((partner, index) => (
                                                <th key={index}>{partner}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            departmentSplitArray.map((department, index) => {
                                                let rowClass = "";
                                                if (index % 2) {
                                                    rowClass = "program-modal-row-background-color"
                                                }
                                                return (
                                                    <tr key={index} className={rowClass}>
                                                        <td className="padding-right-10 padding-left-5">{department}</td>
                                                        {
                                                            partnerSplitArray.map((partner, subIndex) => {
                                                                return (
                                                                    <td key={subIndex}>
                                                                        {partnerSplitData[partner][department]}
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </>)
                        }
                    </div>
                    <div className="bottom-margin-20 width-100">
                        <div className="col-md-12">
                            <div className="form-wrap program-candidate-material-table-div">
                                <div className="pagetitle row" style={{ color: 'black', paddingTop: 15 }}>
                                    <div className="col-md-10 sm_device"><i className={`${USERS_ICON}`}></i> Candidate Section</div>
                                </div>
                                <div style={{ borderBottom: '1px solid lightgray', marginBottom: 15, marginTop: 15 }} />
                                <input
                                    type="file"
                                    accept=".xlsx"
                                    onChange={handleCandidateExcelUpload}
                                    onClick = {(e) =>{ e.target.files[0] && handleCandidateExcelUpload(e)}}
                                    style={{ display: "none" }}
                                    ref={fileInputRef}
                                />
                                <div className="form-button-div">
                                    {
                                        importLoading === true ? (
                                            <div className="ml-5">
                                                <div className="spinner-loader">
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="import-candidate-button-div">
                                                <button className="button-secondary width-auto ml-5" onClick={importCandidateExcel}>Import Candidate&nbsp;&nbsp;&nbsp;<i className={EXPORT_ICON} /></button>
                                            </div>
                                        )
                                    }
                                </div>
                                <ThemeProvider theme={mytheme}>
                                    <MaterialTable
                                        columns={columns}
                                        data={selectedCandidateList.map((item) => ({
                                            firstName: item.first_name,
                                            lastName: item.last_name,
                                            fatherName: item.fathers_name,
                                            disaplayDobDate: new Date(item.date_of_birth).toLocaleDateString('en-IN', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            }),
                                            dobDate: item.date_of_birth,
                                            ageNumber: item.age,
                                            mobileNumber: item.mobile_no,
                                            adharNo: item.adhaar_no,
                                            email: item.email,
                                            qualification: item.qualification,
                                            city: item.city,
                                            state: item.state,
                                            gender: item.gender,
                                            selected: item.selected,
                                            id: item._id,
                                            departmentSelected: item.department_selected,
                                            trainingPartner: item.training_partner,
                                            employerUnit: item.employer_unit,
                                            ageBelowEighteen: item.ageBelowEighteen,
                                            creationDate: new Date(item.created_date_time).toLocaleDateString('en-IN', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            }),
                                        }))}
                                        actions={actions}
                                        options={{
                                            actionsColumnIndex: -1,
                                            headerStyle: { color: "#999998", fontWeight: "bolder", fontSize: 15 },
                                            sorting: true,
                                            columnsButton: true,
                                            exportButton: true,
                                            exportFileName: "Project Candidates",
                                            rowStyle: (rowData) => {
                                                return {
                                                    backgroundColor: rowData.ageBelowEighteen && BELOW_EIGHTEEN_COLOR,
                                                };
                                            }
                                        }}

                                        components={{
                                            Toolbar: (props) => (
                                                <div>
                                                    <MTableToolbar {...props} />
                                                    {
                                                        selectedCandidateList && loadingComplete === true && (
                                                            <ExcelExportComponent {...props} candidateSplit={candidateSplit} unitSplitData={unitSplitData} partnerSplitData={partnerSplitData} />
                                                        )
                                                    }
                                                </div>
                                            ),
                                        }}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-button-div padding-top20">
                    <button className="brand-button ml-5" onClick={submitDetails}>{programId ? "Update" : "Create"} </button>
                </div>
            </div>
            <ImportErrorPopup modelOpen={isModelOpen} closeModel={cancelHandle} candidateImportData = {candidateImportData}/>
        </>
    )
}

export default CandidateManagementPage;